import IndustryPageUpdated from "components/industries/updated/IndustryPageUpdated";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function EducationIndustry() {
  const renderContent = data => {
    const pageData = {
      industry: "Education",
      header: {
        headerTitle:
          "Create Safer Schools with Reliable Campus-Wide Visibility",
        headerParagraph:
          "Safeguard students with a cloud-based platform that makes it easy to manage security across your entire campus or district. Proactively prepare for emergencies and respond swiftly when threats arise.",
        headerImg: data.headerImage,
        mobileHeaderImg: data.headerImageMobile,
      },
      industryTitle: "Trusted by Education Leaders",
      industryLogos: [
        {
          img: data.logo1,
          width: "89px",
          alt: "Clark Atlanta Univeristy",
        },
        {
          img: data.logo2,
          width: "118px",
          alt: "Jesuit",
        },
        {
          img: data.logo3,
          width: "135px",
          alt: "Mississippi Department of Education",
        },
        {
          img: data.logo4,
          width: "99px",
          alt: "Pacific University Oregon",
        },
        {
          img: data.logo5,
          width: "174px",
          alt: "Conestoga Valley",
        },
        {
          img: data.logo6,
          width: "158px",
          alt: "Los Angeles Film School",
        },
      ],

      learnRows: [
        {
          image: data.learnImage1,
          title:
            "Keep Students and Faculty Safe with a User-Friendly Platform ",
          description:
            "Fortify school security with cloud-based cameras and real-time AI alerts for proactive threat detection anytime, anywhere. Cover blind spots, support School Resource Officers, and streamline investigations with easy-to-use tools.",
          linkText: "Learn About Cloud-Based Cameras",
          linkUrl: "/cameras",
        },
        {
          image: data.learnImage2,
          title:
            "Accelerate Emergency Response and Coordinate with First Responders",
          description:
            "Act swiftly in emergencies and access a range of solutions, including person-of-interest detection, panic buttons, and alarm monitoring. Verify threats, reduce false alarms, and relay key details to emergency services efficiently.",
          linkText: "Learn About Alarm Monitoring",
          linkUrl: "/alarm-monitoring/",
        },
        {
          image: data.learnImage3,
          title: "Monitor Air Quality and Detect Student Vaping",
          description:
            "Maintain a healthy school environment and reduce student vaping with air quality and vape/smoke/THC smart sensors. Automatically detect e-cigarette and tobacco use in real time and obtain actionable evidence while preserving student privacy.",
          linkText: "Learn About IoT Sensors",
          linkUrl: "/sensors",
        },
        {
          image: data.learnImage4,
          title: "Control Campus Perimeters and Building Access",
          description:
            "Centrally manage unlimited doors, buildings, and credentials from anywhere. Detect unauthorized visitors and quickly understand who’s on school property by pairing access events with synchronized video, badge data, and time stamps.",
          linkText: "Learn About Access Control",
          linkUrl: "/access-control",
        },
        {
          image: data.learnImage5,
          title: "Meet State Compliance",
          description:
            "Stay up to date with school security regulations at the state and federal levels. With modern infrastructure, a highly scalable system, and automatic updates at no cost, it’s easy to maintain compliance as security needs evolve today and in the future.",
          linkText: "Learn About Compliance",
          linkUrl: "/compliance",
        },
      ],
      industryUseCases: [
        {
          title: "Rhombus in Primary Schools",
          tabTitle: "Primary Schools",
          description:
            "Provide peace of mind to parents and create a safe learning environment in preschools, daycares, and elementary schools.",
          image: data.useCase1,
          mobileImage: data.useCaseMobile1,
        },
        {
          title: "Rhombus in Middle & High Schools",
          tabTitle: "Middle & High Schools",

          description:
            "Protect students, resolve conflicts, and cover blind spots. Secure classrooms, cafeterias, gyms, parking lots, and more.",
          image: data.useCase2,
          mobileImage: data.useCaseMobile2,
        },
        {
          title: "Rhombus in Colleges & Universities",
          tabTitle: "Colleges & Universities",
          description:
            "Unify security across all campus buildings. Create 24/7 safe spaces in dorms, libraries, lecture halls, stadiums, and more.",
          image: data.useCase3,
          mobileImage: data.useCaseMobile3,
        },
        {
          title: "Rhombus in Special Education",
          tabTitle: "Special Education",

          description:
            "Create a nurturing environment that protects students and staff. Provide video monitoring to maintain compliance and accountability.",
          image: data.useCase4,
          mobileImage: data.useCaseMobile4,
        },
      ],
      features: [
        {
          icon: data.icon1,
          iconAlt: "camera",
          title: "Protect Students and Staff",
          p:
            "Increase campus-wide visibility with high-resolution video and AI analytics to quickly identify, deter, and resolve unwanted events.",
        },
        {
          icon: data.icon2,
          iconAlt: "lightening bolt",
          title: "Swiftly Respond to Incidents",
          p:
            "Receive proactive real-time alerts for unusual behavior and share live footage with emergency services from the Rhombus Mobile App.",
        },
        {
          icon: data.icon3,
          iconAlt: "shield",
          title: "Stay Ahead of Threats",
          p:
            "Utilize industry-leading AI analytics across all devices to receive proactive alerts for unauthorized people, smoking or vaping, and flagged vehicles.",
        },
        {
          icon: data.icon4,
          iconAlt: "check mark",
          title: "Simplify Management",
          p:
            "No DVR/NVRs or complex configurations required. Cameras come online in minutes, include native remote access, and update automatically.",
        },
      ],
      form: {
        title: "Request Demo",
        id: "fa7fb89b-ef66-4638-aa6e-e35b012fc6c6",
        paragraph:
          "Contact our team today and see why Rhombus is the leading physical security solution for businesses of all sizes.",
        caseStudy: {
          title: "Rhombus is Trusted by Education Leaders",
          image: data.qouteImage,
          logo: {
            img: data.logo1,
            width: "89px",
            alt: "Clark Atlanta Univeristy",
          },
          link: "/case-studies/clark-atlanta-university/",
          author: {
            name: "Dr. Bo Tang",
            role: "Director of Enterprise Architecture at CAU",
            qoute:
              "“I have been in the technology industry for many years, and <strong>I like the way Rhombus does business.</strong> They genuinely care about creating a product and service that fits their users’ needs.”",
          },
        },
      },
    };
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus for Education - Safer Schools and Universities</title>
          <meta
            name="description"
            content="Discover how Rhombus school security cameras, vape sensors, and access control solutions improve security for K-12 campuses and higher-ed universities."
          />
        </Helmet>
        <IndustryPageUpdated data={pageData} />
      </GlobalLayout>
    );
  };
  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 668)
        }
      }
      headerImageMobile: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-hero-image-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, quality: 100, width: 350)
        }
      }
      logo1: file(
        relativePath: { eq: "components/industries/img/education/logo-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo2: file(
        relativePath: {
          eq: "components/industries/img/education/logo2-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo3: file(
        relativePath: {
          eq: "components/industries/img/education/logo3-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo4: file(
        relativePath: {
          eq: "components/industries/img/education/logo4-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo5: file(
        relativePath: { eq: "components/industries/img/education/logo-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      logo6: file(
        relativePath: {
          eq: "components/industries/img/education/logo6-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      icon1: file(
        relativePath: { eq: "components/industries/img/icons/shield.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon2: file(
        relativePath: { eq: "components/industries/img/icons/efficient.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon3: file(
        relativePath: { eq: "components/industries/img/icons/learning.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      icon4: file(
        relativePath: { eq: "components/industries/img/icons/system.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/cameras-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/education-alarm-monitoring.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/environmental-monitoring-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/privacy-screen-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      learnImage5: file(
        relativePath: {
          eq: "components/industries/updated/img/education/smart-sensors-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/primary-school-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/middle-high-school-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/college-uni-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCase4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/special-ed-2000-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile1: file(
        relativePath: {
          eq: "components/industries/updated/img/education/mobile-primary-school-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile2: file(
        relativePath: {
          eq: "components/industries/updated/img/education/mobile-middle-high-school-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile3: file(
        relativePath: {
          eq: "components/industries/updated/img/education/mobile-college-uni-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      useCaseMobile4: file(
        relativePath: {
          eq: "components/industries/updated/img/education/mobile-special-ed-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      qouteImage: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/cau-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      avatar: file(
        relativePath: {
          eq: "components/industries/updated/img/case-studies/daniel-ruiz-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
